import gql from 'graphql-tag';

export const getAuthenticationState = gql`
  query {
    authenticationState @client {
      authenticated
      user
      selectedAccount
      accounts
    }
  }
`;

export const getAuthenticationStateOptions = ({
  name: 'authenticationState'
});