import React, { Component } from "react";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import { getPublishedPreview, getPublishedPreviewOptions } from "../graphql";
import { renderWhileLoading, renderForError } from "../apollo";

const GET_GALLERY = gql`
  query getGallery($galleryId: String!) {
    gallery(galleryId: $galleryId)
      @rest(type: "Gallery", path: "public/{args.galleryId}/feed") {
      data @type(name: "GalleryData") {
        posts @type(name: "GalleryPost") {
          data @type(name: "GalleryPostData") {
            thumbnailUrl
          }
        }
      }
      profile_picture_url
      username
    }
  }
`;

class PublicPreview extends Component {
  render() {
    let { publishedPreview } = this.props.publishedPreview;

    return (
      <section className="container">
        <div className="profile-header">
          <img
            className="profile-image profile-image-circle"
            src={
              this.props.gallery && this.props.gallery.profile_picture_url
                ? this.props.gallery.profile_picture_url
                : publishedPreview.instagram.profileImageUrl
            }
            alt={`Profile of ${publishedPreview.instagramUsername}`}
          />
          <h3 className="profile-username">
            @{publishedPreview.instagramUsername}
          </h3>
        </div>
        {publishedPreview.hasIntroduction && (
          <div className="introduction">
            <p>{publishedPreview.introductionText}</p>
          </div>
        )}
        <div className="buttons">
          {publishedPreview.buttons.map((button, index) => (
            <div key={index} className="button">
              <a href={button.url}>{button.text}</a>
            </div>
          ))}
        </div>
        {publishedPreview.hasGallery && (
          <div className="gallery">
            {publishedPreview.galleryTitle !== "" && (
              <h2 className="galleryTitle">{publishedPreview.galleryTitle}</h2>
            )}
            <div className="row">
              {this.props.gallery &&
                this.props.gallery.gallery &&
                this.props.gallery.gallery.data &&
                this.props.gallery.gallery.data.posts &&
                this.props.gallery.gallery.data.posts.map((post, index) => {
                  return (
                    <div key={index} className="cellContainer">
                      <a className="cell" href="#">
                        <img src={post.data.thumbnailUrl} />
                      </a>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </section>
    );
  }
}

export default compose(
  graphql(getPublishedPreview, getPublishedPreviewOptions),
  graphql(GET_GALLERY, {
    name: "gallery",
    options: ({
      publishedPreview: {
        publishedPreview: { galleryId }
      }
    }) => ({ variables: { galleryId } }),
    skip: props => {
      return (
        !props.publishedPreview ||
        props.publishedPreview.loading ||
        props.publishedPreview.error ||
        !props.publishedPreview.publishedPreview.hasGallery
      );
    }
  }),
  renderWhileLoading("publishedPreview"),
  renderForError("publishedPreview")
)(PublicPreview);
