import ApolloClient from "apollo-client";
import { withClientState } from "apollo-link-state";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { RestLink } from "apollo-link-rest";
import { onError } from "apollo-link-error";

import { auth } from "../firebase";
import defaults from "./defaults";
import resolvers from "./resolvers";
import { renderForError } from "./renderForError";
import { renderWhileLoading } from "./renderWhileLoading";

const httpLink = createHttpLink({
  uri: "https://server.sked.link/graphql"
});

const restLink = new RestLink({ uri: "https://app.skedsocial.com/" });

var authLink = setContext(async (_, { headers }) => {
  let theHeader = {
    headers: {
      ...headers
    }
  };

  if (auth.currentUser) {
    try {
      const token = await auth.currentUser.getIdToken(true);
      if (token) {
        theHeader.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      console.log(error);
    }
  }

  return theHeader;
});

const errorLink = onError(
  ({ response, operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        if (message.indexOf("Not authenticated") > -1) {
          console.log("Caught unauthorized errro");
          auth.signOut();
        }
      });
    }
  }
);

const cache = new InMemoryCache();

const stateLink = withClientState({
  cache,
  defaults,
  resolvers
});

export var client = new ApolloClient({
  cache,
  link: ApolloLink.from([stateLink, restLink, authLink, errorLink, httpLink])
});

export { renderForError, renderWhileLoading };
