import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { Route, Redirect } from 'react-router-dom';
import { getAuthenticationState, getAuthenticationStateOptions } from '../graphql';
import { renderForError, renderWhileLoading } from '../apollo';

class AuthenticatedRoute extends Component {
    render() {
        let { authenticationState } = this.props.authenticationState;
        let CustomComponent = this.props.component;

        return (
            <Route
                {...this.props}
                component={props => (
                    authenticationState.authenticated
                    ? <CustomComponent {...props} />
                    : <Redirect to='/login' />
                )}
            />
        );
    }
}

export default compose(
    graphql(getAuthenticationState, getAuthenticationStateOptions),
    renderForError('authenticationState'),
    renderWhileLoading('authenticationState')
)(AuthenticatedRoute);