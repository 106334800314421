import React from 'react';
import { getIn } from 'formik';
import { FormField, TextInput, TextInputField, Switch, Select } from 'evergreen-ui';
import { TwitterPicker } from 'react-color';

export const WrappedTextInput = ({ field, form: { touched, errors }, ...props }) => (
    <TextInput isInvalid={getIn(touched, field.name) && getIn(errors, field.name) ? true : false} {...field} {...props} />
);
export const WrappedTextInputField = ({ field, form: { touched, errors }, ...props }) => (
    <TextInputField isInvalid={getIn(touched, field.name) && getIn(errors, field.name) ? true : false} 
    validationMessage={getIn(touched, field.name) && getIn(errors, field.name)} {...field} {...props} />
);
export const WrappedSwitch = ({ field, form, ...props }) => {
    field.checked = field.value;
    delete field.value;

    return (<Switch height={24} {...field} {...props} />)
};

export const WrappedSwitchField = ({ field, form: { touched, errors }, ...props }) => {
    field.checked = field.value;
    delete field.value;

    return (
        <FormField {...props} isInvalid={getIn(touched, field.name) && getIn(errors, field.name) ? true : false} 
        validationMessage={getIn(touched, field.name) && getIn(errors, field.name)}>
            <Switch height={24} {...field} {...props} />
        </FormField>
    )
}

export const WrappedColourPicker = ({ field, form, ...props}) => {
    field.color = field.value;
    field.handleChange = (colour) => {
        field.onChange({ target: {
            type: 'colour',
            name: field.name,
            id: field.id, 
            value: colour.hex 
        } });
    }
    delete field.value;
    return (
        <FormField {...props}>
            <TwitterPicker {...field} {...props} onChange={field.handleChange} triangle="hide" colors={['#001f3f', '#0074D9', '#7FDBFF','#39CCCC','#3D9970','#2ECC40','#01FF70','#FFDC00','#FF851B','#FF4136','#85144b','#F012BE','#B10DC9','#111111','#AAAAAA','#DDDDDD','#FFFFFF']} />
        </FormField>
    );
}

export const WrappedSelect = ({ field, form, ...props}) => {
    return (
        <FormField {...props}>
            <Select {...field} {...props}>
                {props.options.map(({ label, value }, index) => (<option key={index} value={value}>{label}</option>))}
            </Select>
        </FormField>
    );
}