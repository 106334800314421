import { renderComponent, branch, withProps } from "recompose";
import ErrorView from "../components/Error";
import { compose } from "react-apollo";

export const renderForError = (propName = "data") =>
  compose(
    withProps(props => ({ error: props[propName] && props[propName].error })),
    branch(
      props => props[propName] && props[propName].error,
      renderComponent(ErrorView)
    )
  );
