import firebase from 'firebase/app';
import 'firebase/auth';

const stagingConfig = {
  apiKey: "AIzaSyBG5VgBqUDvv3mbPXLT-iILjsT5h6K-MBs",
  authDomain: "sked-link-87d5e.firebaseapp.com",
  databaseURL: "https://sked-link-87d5e.firebaseio.com",
  projectId: "sked-link-87d5e",
  storageBucket: "sked-link-87d5e.appspot.com",
  messagingSenderId: "4325095297"
};

const productionConfig = {
  apiKey: "AIzaSyDjyxbJf6IMYdUuHCdBqCsP2F9b3um2Mbk",
  authDomain: "sked-link-production.firebaseapp.com",
  databaseURL: "https://sked-link-production.firebaseio.com",
  projectId: "sked-link-production",
  storageBucket: "sked-link-production.appspot.com",
  messagingSenderId: "608841692012"
};

if (!firebase.apps.length) {
    firebase.initializeApp(productionConfig);
}

const auth = firebase.auth();

export {
  auth,
  firebase
};