import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Pane, Button, Spinner, Heading, Card } from 'evergreen-ui';
import * as Yup from 'yup';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import { createInstagram, createInstagramOptions, updateAuthenticationState, updateAuthenticationStateOptions, getAuthenticationState, getAuthenticationStateOptions } from '../graphql';
import { renderWhileLoading, renderForError } from '../apollo';
import { WrappedTextInputField, WrappedSwitchField } from './WrappedFields';

const CreateInstagramSchema = Yup.object().shape({
    instagramUsername: Yup.string().matches(/^[a-zA-Z0-9_.]*$/, 'Must be a valid instagram username (make sure not include @)').required().label('Instagram username')
});

class Create extends Component {
    state = {
        error: false,
        errorMessage: undefined
    };

    render() {
        let that = this;
        let { createInstagram, updateAuthenticationState, authenticationState: { authenticationState }, history } = this.props;
        return (
            <>
                <Helmet>
                    <title>Sked Link - Add Instagram account</title>
                </Helmet>
                <Pane display="flex" flexDirection="column" padding={16} borderRadius={3}>
                    <Heading size={800} marginBottom={8}>Add your Instagram account</Heading>
                    <Formik
                        initialValues={{
                            instagramUsername: '',
                            migrate: false
                        }}
                        validationSchema={CreateInstagramSchema}
                        onSubmit={async (values) => {
                            try {
                                let newInstagram = await createInstagram({
                                    variables: {
                                        username: values.instagramUsername,
                                        migrate: values.migrate,
                                        migrateFrom: 'linktree'
                                    }
                                });

                                let variables = {
                                    selectedAccount: values.instagramUsername,
                                    accounts: [values.instagramUsername]
                                };
                                if (authenticationState.accounts.length > 0) {
                                    variables.accounts = [...authenticationState.accounts, values.instagramUsername];
                                }

                                await updateAuthenticationState({
                                    variables
                                });

                                ReactGA.event({
                                    category: 'User',
                                    action: 'Created Instagram'
                                });

                                if (window._hsq) {
                                    window._hsq.push(["trackEvent", {
                                        id: "sked_link_created"
                                    }]);
                                }

                                if (window.Intercom) {
                                    window.Intercom('trackEvent', 'sked-link-created');
                                }
                                if(window._ctrack){
                                  window._ctrack.track("sked-link-created");
                                }

                                history.push('/build');
                            } catch (error) {
                                that.setState({
                                    error: true,
                                    errorMessage: error.graphQLErrors
                                });
                            }
                        }}
                        render={({ values, errors, touched, isSubmitting }) => {
                            if (this.state.error) {
                                return <div>{ this.state.errorMessage && this.state.errorMessage.length > 0 ? this.state.errorMessage[0].message : 'Something went wrong'}.</div>
                            } else if (isSubmitting) {
                                return <Spinner />;
                            } else {
                                return (<Form>
                                    <Card padding={8} margin={8} background="tint2" maxWidth={480}>
                                        <Field name="instagramUsername" label="Instagram username" hint="The bit after the @. For example, if you're @nike - write nike" component={WrappedTextInputField} />
                                        <Field name="migrate" label="Migrate automatically" hint="If you're currently using linktree you can enable this to migrate your links automatically" component={WrappedSwitchField} />
                                        <Button appearance="primary" type="submit" marginTop={4}>Add</Button>
                                    </Card>
                                </Form>);
                            }
                        }}
                    />
                </Pane>
            </>
        );
    }
}

export default compose(
    graphql(getAuthenticationState, getAuthenticationStateOptions),
    graphql(createInstagram, createInstagramOptions),
    graphql(updateAuthenticationState, updateAuthenticationStateOptions),
    renderWhileLoading('createInstagram'),
    renderWhileLoading('authenticationState'),
    renderForError('createInstagram'),
    renderForError('authenticationState')
)(Create);
