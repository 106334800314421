import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Pane } from 'evergreen-ui';

import Build from './Build';
import Header from './Header';
import Login from './Login';
import Integrate from './Integrate';
import Design from './Design';
import Create from './Create';
import SkedAuth from './SkedAuth';
import AuthenticatedRoute from './AuthenticatedRoute';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import PublicPreview from './PublicPreview';
import Preview from './Preview';
import withPreview from './withPreview';
import withSticky from './withSticky';

class App extends Component {

  render() {
    return (
      <Pane height="100%">
        <Header />
        <Pane height="calc(100% - 64px)">
            <Switch>
              <Route exact path="/auth/redirect/:token" component={SkedAuth} />
              <Route exact path="/preview/:username" component={PublicPreview} />
              <AuthenticatedRoute exact path="/create" component={Create} />
              <ProtectedRoute exact path="/build" component={withPreview(Build)} />
              <ProtectedRoute exact path="/design" component={withPreview(Design)} />
              <ProtectedRoute exact path="/integrate" component={withPreview(Integrate)} />
              <ProtectedRoute exact path="/preview" component={withSticky(Preview)} />
              <PublicRoute exact path="/login" component={Login} />
              <PublicRoute path={null} component={Redirect} to="/login" />
            </Switch>
        </Pane>
      </Pane>
    );
  }
}

export default App;
