import gql from 'graphql-tag';

export const skedAuth = gql`
    mutation skedAuth(
        $token: String!
    ) {
        skedAuth(token: $token) {
            token
        }
    }
`;

export const skedAuthOptions = ({
    name: 'skedAuth'
});