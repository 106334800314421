import React, { Component } from "react";
import { graphql, compose } from "react-apollo";
import { branch, renderComponent } from "recompose";
import gql from "graphql-tag";
import { Pane, Badge, Tooltip, Alert } from "evergreen-ui";
import moment from "moment";
import Moment from "react-moment";
import { Sticky } from "react-sticky";
import { Helmet } from "react-helmet";

import {
  getAuthenticationState,
  getAuthenticationStateOptions,
  getDraftPreview,
  getDraftPreviewOptions
} from "../graphql";
import { renderWhileLoading, renderForError } from "../apollo";
import Loading from "./Loading";
import withDevice from "./withDevice";

const GET_GALLERY = gql`
  query getGallery($galleryId: String!) {
    gallery(galleryId: $galleryId)
      @rest(type: "Gallery", path: "public/{args.galleryId}/feed") {
      data @type(name: "GalleryData") {
        posts @type(name: "GalleryPost") {
          data @type(name: "GalleryPostData") {
            thumbnailUrl
          }
        }
      }
      profile_picture_url
      username
    }
  }
`;

class Preview extends Component {
  render() {
    console.log(
      "Props",
      this.props,
      this.props.draftPreview.hasRedirect,
      this.props.draftPreview.galleryRedirect
    );
    let { draftPreview } = this.props.draftPreview;

    let InnerPreview = () => {
      if (
        draftPreview &&
        (draftPreview.hasRedirect === true ||
          draftPreview.galleryRedirect === true)
      ) {
        return (
          <section
            className="container"
            style={{
              backgroundColor: draftPreview.style.background,
              color: draftPreview.style.fontColour,
              height: "100%",
              paddingTop: "32px",
              paddingLeft: "10px",
              paddingRight: "5px"
            }}
          >
            <h3 style={{ paddingTop: "20px" }}>
              You have turned on the redirect feature - no page will be shown
            </h3>
            <p style={{ fontSize: "16px", color: "black" }}>
              When you enable the "Redirect to latest post" or "Redirect"
              features, we will send visitors to the latest post URL or the
              provided URL rather than your Sked Link page.{" "}
            </p>
            <p style={{ fontSize: "16px", color: "black" }}>
              Disable these feature(s) to instead show visitors a page with
              buttons or a gallery.
            </p>
          </section>
        );
      } else {
        return (
          <section
            className="container"
            style={{
              backgroundColor: draftPreview.style.background,
              color: draftPreview.style.fontColour,
              height: "100%",
              paddingTop: "32px"
            }}
          >
            {draftPreview.hasProfileHeader && (
              <div className="profile-header">
                <img
                  className="profile-image profile-image-circle"
                  src={
                    this.props.gallery &&
                    this.props.gallery.gallery &&
                    this.props.gallery.gallery.profile_picture_url
                      ? this.props.gallery.gallery.profile_picture_url
                      : draftPreview.instagram.profileImageUrl
                  }
                  alt={`Profile of ${draftPreview.instagramUsername}`}
                />
                <h3 className="profile-username">
                  @{draftPreview.instagramUsername}
                </h3>
              </div>
            )}
            {draftPreview.hasIntroduction && (
              <div className="introduction">
                <p>{draftPreview.introductionText}</p>
              </div>
            )}
            <div className="buttons">
              {draftPreview.buttons.map((button, index) => {
                if (!button.enabled) {
                  return false;
                }

                return (
                  <div
                    key={index}
                    className="button"
                    style={{
                      borderRadius: draftPreview.style.buttonRadius,
                      borderWidth: draftPreview.style.buttonBorder,
                      borderColor: draftPreview.style.buttonBorderColour,
                      backgroundColor: draftPreview.style.buttonBackground,
                      opacity: draftPreview.style.buttonOpacity / 100,
                      boxShadow: `6px 7px 0px 0px ${draftPreview.style.buttonBoxShadowColour}`
                    }}
                  >
                    <a
                      href={button.url}
                      style={{
                        color: draftPreview.style.buttonFontColour
                      }}
                    >
                      {button.text}
                    </a>
                  </div>
                );
              })}
            </div>
            {draftPreview.hasGallery && (
              <div className="gallery">
                {draftPreview.galleryTitle !== "" && (
                  <h3 className="gallery-title">{draftPreview.galleryTitle}</h3>
                )}
                {this.props.gallery && this.props.gallery.gallery === null && (
                  <Alert intent="danger">
                    Your gallery is enabled but the provided key is not
                    returning any data. Please check the account key.
                  </Alert>
                )}
                <div className="row">
                  {this.props.gallery &&
                    this.props.gallery.gallery &&
                    this.props.gallery.gallery.data &&
                    this.props.gallery.gallery.data.posts &&
                    this.props.gallery.gallery.data.posts.map((post, index) => {
                      return (
                        <div key={index} className="cellContainer">
                          <a className="cell" href="#">
                            <img src={post.data.thumbnailUrl} />
                          </a>
                          {post.data.redirectUrl && (
                            <svg
                              className="link-icon"
                              data-icon="link"
                              viewBox="0 0 16 16"
                              style={{ fill: "white" }}
                            >
                              <title>link</title>
                              <path
                                d="M4.99 11.99c.28 0 .53-.11.71-.29l6-6a1.003 1.003 0 0 0-1.42-1.42l-6 6a1.003 1.003 0 0 0 .71 1.71zm3.85-2.02L6.4 12.41l-1 1-.01-.01c-.36.36-.85.6-1.4.6-1.1 0-2-.9-2-2 0-.55.24-1.04.6-1.4l-.01-.01 1-1 2.44-2.44c-.33-.1-.67-.16-1.03-.16-1.1 0-2.09.46-2.81 1.19l-.02-.02-1 1 .02.02c-.73.72-1.19 1.71-1.19 2.81 0 2.21 1.79 4 4 4 1.1 0 2.09-.46 2.81-1.19l.02.02 1-1-.02-.02c.73-.72 1.19-1.71 1.19-2.81 0-.35-.06-.69-.15-1.02zm7.15-5.98c0-2.21-1.79-4-4-4-1.1 0-2.09.46-2.81 1.19l-.02-.02-1 1 .02.02c-.72.72-1.19 1.71-1.19 2.81 0 .36.06.69.15 1.02l2.44-2.44 1-1 .01.01c.36-.36.85-.6 1.4-.6 1.1 0 2 .9 2 2 0 .55-.24 1.04-.6 1.4l.01.01-1 1-2.43 2.45c.33.09.67.15 1.02.15 1.1 0 2.09-.46 2.81-1.19l.02.02 1-1-.02-.02a3.92 3.92 0 0 0 1.19-2.81z"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </section>
        );
      }
    };

    let DevicePreview = withDevice(InnerPreview);

    return (
      <>
        <Helmet>
          <style>{`${draftPreview.css}`}</style>
        </Helmet>
        <Pane
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginTop={36}
          marginBottom={8}
          style={{ minWidth: 447 }}
        >
          <Tooltip content={moment(parseInt(draftPreview.updatedAt)).format()}>
            <Badge color="neutral">
              draft saved{" "}
              <Moment fromNow interval={5000}>
                {parseInt(draftPreview.updatedAt)}
              </Moment>
            </Badge>
          </Tooltip>
        </Pane>
        <Sticky>
          {({ style }) => (
            <Pane height="100%" style={style}>
              <Pane display="flex" justifyContent="center">
                <DevicePreview />
              </Pane>
            </Pane>
          )}
        </Sticky>
      </>
    );
  }
}

const renderIfNotAuthenticated = component =>
  branch(
    props =>
      !props.authenticationState ||
      !props.authenticationState.authenticationState ||
      !props.authenticationState.authenticationState.authenticated,
    renderComponent(component)
  );

export default compose(
  graphql(getAuthenticationState, getAuthenticationStateOptions),
  graphql(getDraftPreview, getDraftPreviewOptions),
  graphql(GET_GALLERY, {
    name: "gallery",
    options: ({
      draftPreview: {
        draftPreview: { galleryId }
      }
    }) => ({ variables: { galleryId } }),
    skip: props => {
      return (
        !props.draftPreview ||
        props.draftPreview.loading ||
        props.draftPreview.error ||
        !props.draftPreview.draftPreview.galleryId
      );
    }
  }),
  renderIfNotAuthenticated(Loading),
  renderWhileLoading("authenticationState"),
  renderWhileLoading("draftPreview"),
  renderWhileLoading("gallery"),
  renderForError("draftPreview"),
  renderForError("authenticationState"),
  renderForError("gallery")
)(Preview);
