import React from 'react';
import { PropTypes } from 'prop-types';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import 'autotrack';

import { auth } from './firebase';
import history from './history';
import { updateAuthenticationState, getDefaultAccount } from './graphql';
import { client } from './apollo';
import * as serviceWorker from './serviceWorker';
import './styles/index.css';
import './styles/Preview.css';
import App from './components/App';

ReactGA.initialize('UA-46264715-8');
ReactGA.plugin.require('outboundLinkTracker');
ReactGA.plugin.require('pageVisibilityTracker');
ReactGA.plugin.require('maxScrollTracker');
ReactGA.pageview(window.location.pathname + window.location.search);

class GAListener extends React.Component {
    static contextTypes = {
        router: PropTypes.object
    };

    componentDidMount() {
        this.sendPageView(this.context.router.history.location);
        this.context.router.history.listen(this.sendPageView);
    }

    sendPageView(location) {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
        ReactPixel.pageView();
        if (window._hsq) {
            window._hsq.push(['setPath', location.pathname]);
            window._hsq.push(['trackPageView']);
        }

        if (window.Intercom) {
            window.Intercom('update');
        }
    }

    render() {
      return this.props.children;
    }
}

auth.onAuthStateChanged(async (user) => {
    if (user) {
        let {
            email,
            displayName,
            emailVerified,
            uid
        } = user;

        ReactPixel.init('311864732777197', { firebaseId: uid });
        ReactPixel.pageView();
        ReactGA.set({ userId: uid });
        if (window._hsq) {
            window._hsq.push(["identify", {
                email,
                sked_link: true
            }]);
        }

        if (window.Intercom) {
            let intercomHash = window.localStorage.getItem('intercom-hash');
            let intercomId = window.localStorage.getItem('intercom-id');

            if (intercomHash) {
                window.Intercom('boot', {
                    app_id: 'e9h91cip',
                    email: email,
                    user_id: intercomId,
                    user_hash: intercomHash
                 });
            }
        }
        if(window._ctrack){
          window._ctrack.identify({
            userId: uid, // Your Id of the user
            email: email, // Email of the user
          });
        }

        let defaultAccount = await client.query({
            query: getDefaultAccount
        });
        let { instagrams } = defaultAccount.data;

        let newAuthenticationState = {
            authenticated: true,
            user: {
                email,
                displayName,
                emailVerified,
                uid
            },
            accounts: [],
            selectedAccount: ''
        };

        if (instagrams && instagrams.length > 0) {
            newAuthenticationState.selectedAccount = defaultAccount.data.instagrams[0].username
            newAuthenticationState.accounts = defaultAccount.data.instagrams.map(instagram => instagram.username);
        }

        let result = await client.mutate({
            mutation: updateAuthenticationState,
            variables:  newAuthenticationState
        });

        const token = await auth.currentUser.getIdToken(true);
    } else {
        await client.resetStore();
        ReactPixel.init('311864732777197');
        ReactPixel.pageView();
        if (window._hsq) {
            window._hsq.push(['revokeCookieConsent']);
        }
        if (window.Intercom) {
            window.Intercom('boot', {
                app_id: 'e9h91cip'
             });
        }
        //history.push('/');
    }

    ReactDOM.render(
        <Router history={history}>
            <GAListener>
                <ApolloProvider client={client}>

                    <App />
                </ApolloProvider>
            </GAListener>
        </Router>,
        document.getElementById('root')
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
