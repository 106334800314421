import { Component } from 'react';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import { connect } from 'formik';

class AutoSave extends Component {
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.formik.values, this.props.formik.values)) {
            this.commit();
        }
    }

    commit = debounce(async () => {
        this.props.formik.handleSubmit(this.props.formik.values);
    }, 300);

    render() {
        return null;
    }
}

export default connect(AutoSave);