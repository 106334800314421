import gql from 'graphql-tag';

export const refreshProfilePicture = gql`
    mutation refreshProfilePicture(
        $instagramUsername: String!
    ) {
        refreshProfilePicture(instagramUsername: $instagramUsername) {
            id
            profileImageUrl
        }
    }
`;

export const refreshProfilePictureOptions = ({
    name: 'refreshProfilePicture'
});