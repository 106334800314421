import gql from 'graphql-tag';

export const publishPreview = gql`
    mutation publishPreview(
        $instagramUsername: String!
    ) {
        publishPreview(instagramUsername: $instagramUsername)
    }
`;

export const publishPreviewOptions = ({
    name: 'publishPreview'
});