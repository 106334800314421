import gql from 'graphql-tag';

export const createInstagram = gql`
    mutation createInstagram(
        $username: String!
        $migrate: Boolean
        $migrateFrom: String
    ) {
        createInstagram(username: $username, migrate: $migrate, migrateFrom: $migrateFrom) {
            id
            username
            owner {
              id
            }
        }
    }
`;

export const createInstagramOptions = ({
    name: 'createInstagram'
});