import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import jwtDecode from 'jwt-decode';

import { getAuthenticationState, getAuthenticationStateOptions, skedAuth, skedAuthOptions } from '../graphql';
import { renderWhileLoading, renderForError} from '../apollo';
import { auth } from '../firebase';

class SkedAuth extends Component {

    authenticate = async (match, skedAuth, history) => {
        let firebaseToken = await skedAuth({ variables: { token: match.params.token }});
        let decodedToken = jwtDecode(match.params.token);

        await auth.signOut();
        await auth.signInWithCustomToken(firebaseToken.data.skedAuth.token);

        window.localStorage.setItem('intercom-hash', decodedToken.intercom.web);
        window.localStorage.setItem('intercom-id', decodedToken.userId);

        history.push('/');
    }

    render() {
        let { skedAuth, match, history } = this.props;

        if (match.params.token) {
            this.authenticate(match, skedAuth, history);
        }

        return (
            <>
                Logging in with your Sked account..
            </>
        );
    }
}


export default compose(
    graphql(getAuthenticationState, getAuthenticationStateOptions),
    graphql(skedAuth, skedAuthOptions),
    renderWhileLoading("authenticationState"),
    renderForError("authenticationState"),
    renderForError("skedAuth"),
)(SkedAuth);