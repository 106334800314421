import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Pane, Button, Card, Heading, Spinner, Paragraph } from 'evergreen-ui';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';

import WithAutoSave from './withAutoSave';
import { getAuthenticationState, getAuthenticationStateOptions, getDraftPreview, getDraftPreviewOptions } from '../graphql';
import { renderWhileLoading, renderForError } from '../apollo';
import { WrappedTextInputField, WrappedColourPicker } from './WrappedFields';

const UPDATE_DRAFT_PREVIEW = gql`
    mutation UPDATE_DRAFT_PREVIEW(
        $css: String!
        $style: PreviewStyleInput!
        $instagramUsername: String!
    ) {
        updatePreview(
            instagramUsername: $instagramUsername,
            css: $css,
            style: $style
        ) {
            id
            css
            style {
                buttonRadius
                buttonBorder
                buttonBorderColour
                buttonFontColour
                buttonBackground
                buttonOpacity
                buttonBoxShadowColour
                fontColour
                background
            }
        }
    } 
`;

const DesignSchema = Yup.object().shape({
    css: Yup.string()
});

class Design extends Component {
    render() {
        let { draftPreview, variables: { instagramUsername } } = this.props.draftPreview;
        let updatePreview = this.props.mutate;

        return (
            <>
                <Helmet>
                    <title>Sked Link - Design</title>
                </Helmet>
                <Pane display="flex" flexDirection="column" borderRadius={3} marginTop={36} marginLeft={36} marginRight={36}>
                    <Formik 
                        initialValues={draftPreview} 
                        validationSchema={DesignSchema}
                        onSubmit={async (values, { setSubmitting }) => { 
                            
                            let variables = {
                                css: values.css,
                                style: {
                                    buttonRadius: values.style.buttonRadius,
                                    buttonBorder: values.style.buttonBorder,
                                    buttonBorderColour: values.style.buttonBorderColour,
                                    buttonFontColour: values.style.buttonFontColour,
                                    buttonBackground: values.style.buttonBackground,
                                    buttonOpacity: values.style.buttonOpacity,
                                    buttonBoxShadowColour: values.style.buttonBoxShadowColour,
                                    fontColour: values.style.fontColour,
                                    background: values.style.background
                                },
                                instagramUsername
                            };

                            await updatePreview({ variables });
                            setSubmitting(false);
                        }}
                        isInitialValid={true}
                        render={({ values, errors, touched, isSubmitting, isValid, setFieldValue }) => (
                            <Form>
                                <Heading size={800}>Presets</Heading>
                                <Card padding={8} marginTop={16} background="tint2">
                                    <Button onClick={() => {
                                        setFieldValue('style', {
                                            buttonRadius: 0,
                                            buttonBorder: 0,
                                            buttonBorderColour: '#000000',
                                            buttonFontColour: '#FFFFFF',
                                            buttonBackground: '#000000',
                                            buttonBoxShadowColour: '#FFFFFF',
                                            buttonOpacity: 100,
                                            fontColour: '#000000',
                                            background: '#FFFFFF'
                                        });
                                    }}>
                                        Classic
                                    </Button>
                                    <Button onClick={() => {
                                        setFieldValue('style', {
                                            buttonRadius: 5,
                                            buttonBorder: 0,
                                            buttonBorderColour: '#000000',
                                            buttonFontColour: '#FFFFFF',
                                            buttonBackground: '#5ABDF9',
                                            buttonBoxShadowColour: '#ECF0EF',
                                            buttonOpacity: 100,
                                            fontColour: '#000000',
                                            background: '#ECF0EF'
                                        });
                                    }}>
                                        Blue Sea
                                    </Button>
                                    <Button onClick={() => {
                                        setFieldValue('style', {
                                            buttonRadius: 20,
                                            buttonBorder: 0,
                                            buttonBorderColour: '#000000',
                                            buttonFontColour: '#FFFFFF',
                                            buttonBackground: '#DC4353',
                                            buttonBoxShadowColour: '#F64073',
                                            buttonOpacity: 100,
                                            fontColour: '#FFFFFF',
                                            background: '#F64073'
                                        });
                                    }}>
                                        Red Hell
                                    </Button>
                                    <Button onClick={() => {
                                        setFieldValue('style', {
                                            buttonRadius: 0,
                                            buttonBorder: 0,
                                            buttonBorderColour: '#000000',
                                            buttonFontColour: '#FFFFFF',
                                            buttonBackground: '#E28AFF',
                                            buttonBoxShadowColour: '#64FFC0',
                                            buttonOpacity: 100,
                                            fontColour: '#3D3B3C',
                                            background: '#FFFFFF'
                                        });
                                    }}>
                                        Retro
                                    </Button>
                                </Card>
                                <Heading size={800} marginTop={48}>General Style</Heading>
                                <Card padding={8} marginTop={16} background="tint2">
                                    <Field name="style.background" label="Background Colour"
                                        hint="The background colour of the page" component={WrappedColourPicker} />
                                    <Field name="style.fontColour" label="Font Colour"
                                        hint="The colour of the text" component={WrappedColourPicker} />
                                </Card>
                                <Heading size={800} marginTop={48}>Button Style</Heading>
                                <Card padding={8} marginTop={16} background="tint2">
                                    <Field type="number" name="style.buttonRadius" placeholder="0" 
                                        hint="The amount of curve on the button corners"
                                        label="Button Radius" component={WrappedTextInputField} />
                                    <Field type="number" name="style.buttonBorder" placeholder="0" 
                                        hint="The width of the border"
                                        label="Border Width" component={WrappedTextInputField} />
                                    <Field type="number" name="style.buttonOpacity" placeholder="100" 
                                        hint="How visible your button is, from 0% to 100%"
                                        label="Opacity" component={WrappedTextInputField} />
                                    <Field name="style.buttonBorderColour" label="Border Colour"
                                        hint="The colour of the border" component={WrappedColourPicker} />
                                        <Field name="style.buttonFontColour" label="Font Colour"
                                            hint="The colour of the text" component={WrappedColourPicker} />
                                    <Field name="style.buttonBackground" label="Background Colour"
                                        hint="The colour of the background of the buttons" component={WrappedColourPicker} />
                                    <Field name="style.buttonBoxShadowColour" label="Box Shadow Colour"
                                        hint="The colour of the box shadow around the button" component={WrappedColourPicker} />                                     
                                </Card>
                                <Card padding={8} marginTop={16} marginBottom={26} background="tint2">
                                    <Field type="textarea" name="css" placeholder="CSS" 
                                        hint={<Paragraph marginTop={6} size={300} color="muted" fontFamily="ui">For advanced users! Customise the design further using CSS. More info <a target="_blank" rel="noopener noreferrer" href='https://support.skedsocial.com/hc/en-us/articles/360022932112-Using-advanced-CSS-with-Sked-Link?utm_source=skedlink&utm_medium=css_support'>here</a>.</Paragraph>}
                                        label="Custom CSS" component={WrappedTextInputField} />
                                    <ErrorMessage name="css" component="div" /> 
                                </Card>
                                { isSubmitting ? <Button appearance="minimal" intent="success" type="button"><Spinner size={24} paddingRight={4}/> saving..</Button> : (!isValid ? <Button appearance="minimal" intent="danger" type="button">Cannot save with invalid input</Button> : <Button appearance="primary" type="submit">Save</Button>) }
                                <WithAutoSave />
                            </Form>
                        )}>
                    </Formik>
                </Pane>
            </>
        );
    }
}

export default compose(
    graphql(getAuthenticationState, getAuthenticationStateOptions),
    graphql(getDraftPreview, getDraftPreviewOptions),
    graphql(UPDATE_DRAFT_PREVIEW),
    renderWhileLoading('authenticationState'),
    renderWhileLoading('draftPreview'),
    renderForError('authenticationState'),
    renderForError('draftPreview')
)(Design);