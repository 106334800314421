import gql from 'graphql-tag';

export const updateAuthenticationState = gql`
    mutation updateAuthenticationState(
        $authenticated: Boolean,
        $user: String,
        $selectedAccount: String
        $accounts: [String]
    ) {
        updateAuthenticationState(authenticated: $authenticated, user: $user, selectedAccount: $selectedAccount, accounts: $accounts) @client {
            authenticated
            user
            selectedAccount
            accounts
          }
    }
`;

export const updateAuthenticationStateOptions = ({
    name: 'updateAuthenticationState'
});