import gql from 'graphql-tag';

export const getPublishedPreview = gql`
  query getPublishedPreview($instagramUsername: String!) {
    publishedPreview(instagramUsername: $instagramUsername) {
      id
      instagramUsername
      language
      hasIntroduction
      hasProfileHeader
      introductionText
      css
      buttons {
          url
          text
          enabled
      }
      hasGallery
      galleryTitle
      galleryId
      instagram {
          profileImageUrl
      }
      updatedAt
    }
  }
`;

export const getPublishedPreviewOptions = ({
  name: 'publishedPreview',
  options: ({ authenticationState: { authenticationState: { authenticated, selectedAccount } } }) => ({ variables: { instagramUsername: selectedAccount } }),
  skip: ({ authenticationState }) => {
    if (authenticationState && authenticationState.authenticationState && authenticationState.authenticationState.selectedAccount) {
      return false;
    }

    return true;
  }
});