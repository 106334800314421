import React, { Component } from 'react';
import { Pane } from 'evergreen-ui';
import { StickyContainer, Sticky } from 'react-sticky';

export default function withSticky(WrappedComponent) {
    return class extends Component {
      render() {
        return (
                    <StickyContainer>
                        <Pane flex={4} alignItems="center" justifyContent="center">
                        <WrappedComponent {...this.props} />
                        </Pane>
                    </StickyContainer>
        );
      }
    };
  }