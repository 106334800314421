import gql from 'graphql-tag';

export const getDefaultAccount = gql`
  query {
    instagrams {
      id
      username
    }
  }
`;

export const getDefaultAccountOptions = ({
  name: 'defaultAccount'
});