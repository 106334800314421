import React, { Component } from 'react';
import { Pane, Heading, Paragraph, UnorderedList, ListItem, Button } from 'evergreen-ui';
import ReactGA from 'react-ga';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { firebase, auth } from '../firebase';

class Login extends Component {
    constructor(props) {
        super(props);
        let authenticatedState = this.props.authenticatedState;

        if (authenticatedState && authenticatedState.authenticated) {
            this.props.history.push('/');
        }
    }

    render() {

        return (
            <Pane display="flex" justifyContent="center" height="100%">
                <Pane flex={3} display="flex" flexDirection="column" padding={64} color="white" style={{ backgroundColor: '#003366', overflow: 'hidden' }}>
                    <Heading size={800} color="white" marginBottom={16}>Sked Link</Heading>
                    <Paragraph size={500} color="white" marginBottom={20}>Manage your Instagram "link in bio" in one place with these three simple steps, for free!</Paragraph>
                    <UnorderedList marginBottom={20}>
                        <ListItem icon="tick-circle" iconColor="success" color="white">Customise the look and feel of your Sked Link</ListItem>
                        <ListItem icon="tick-circle" iconColor="success" color="white">Publish your changes, copy the link and put it in your Instagram bio</ListItem>
                    </UnorderedList>
                    <Paragraph color="white">Sked Link is made by <a style={{color: 'white', fontWeight: 'bold'}} href="https://skedsocial.com/?utm_source=skedlink">Sked Social</a>, the best automated scheduler for Instagram, Facebook and Twitter.</Paragraph>
                </Pane>
                <Pane flex={6} marginTop={64} >
                  <center><h2>Logging you in now…</h2></center><br/>
                </Pane>
            </Pane>
        )
    }
}

export default Login;
