import React, { Component } from "react";
import { Pane, Alert } from "evergreen-ui";

export default class ErrorView extends Component {
  render() {
    let { error } = this.props;
    return (
      <Pane>
        {error.graphQLErrors.map(({ message }, i) => (
          <Alert intent="danger" title={message} key={i} />
        ))}
        {error.networkError && error.networkError.message && (
          <Alert intent="danger" title={error.networkError.message} />
        )}
      </Pane>
    );
  }
}
