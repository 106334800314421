import gql from 'graphql-tag';

export default (_, { authenticated, user, selectedAccount, accounts }, { cache }) => {
    const query = gql`
        query GetAuthenticationState { 
            authenticationState @client {
                authenticated,
                user,
                selectedAccount,
                accounts
            }
        }
    `;

    const previousState = cache.readQuery({ query });

    const data = {
        authenticationState: {
            ...previousState.authenticationState
        }
    };

    if (authenticated) {
        data.authenticationState.authenticated = authenticated;
    }
    if (user) {
        data.authenticationState.user = user;
    }
    if (selectedAccount) {
        data.authenticationState.selectedAccount = selectedAccount;
    }
    if (accounts) {
        data.authenticationState.accounts = accounts;
    }
    
    cache.writeQuery({
        query,
        data
    });

    return null;
};