import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { Route, Redirect } from 'react-router-dom';
import { getAuthenticationState, getAuthenticationStateOptions } from '../graphql';
import { renderForError, renderWhileLoading } from '../apollo';

class PublicRoute extends Component {
    render() {
        let { authenticationState } = this.props.authenticationState;
        let CustomComponent = this.props.component;

        return (
            <Route
                {...this.props}
                component={props => {
                    return (
                    !authenticationState.authenticated
                    ? <CustomComponent {...this.props} {...props} />
                    : <Redirect to='/build' />
                )}}
            />
        );
    }
}

export default compose(
    graphql(getAuthenticationState, getAuthenticationStateOptions),
    renderForError('authenticationState'),
    renderWhileLoading('authenticationState')
)(PublicRoute);