import React, { Component } from 'react';
import '../styles/devices.min.css';

export default function withDevice(WrappedComponent) {
    return class extends Component {
      render() {
        return (
            <div className="marvel-device iphone-x" style={{}}>
                <div className="notch">
                    <div className="camera"></div>
                    <div className="speaker"></div>
                </div>
                <div className="top-bar"></div>
                <div className="sleep"></div>
                <div className="bottom-bar"></div>
                <div className="volume"></div>
                <div className="overflow">
                    <div className="shadow shadow--tr"></div>
                    <div className="shadow shadow--tl"></div>
                    <div className="shadow shadow--br"></div>
                    <div className="shadow shadow--bl"></div>
                </div>
                <div className="inner-shadow"></div>
                <div className="screen" style={{ overflowY: 'scroll' }}>
                    <WrappedComponent />
                </div>
            </div>
        )
      }
    };
  }