import gql from 'graphql-tag';

export const getDraftPreview = gql`
  query getDraftPreview($instagramUsername: String!) {
    draftPreview(instagramUsername: $instagramUsername) {
      id
      instagramUsername
      language
      hasRedirect
      redirectUrl
      hasIntroduction
      hasProfileHeader
      introductionText
      buttons {
          url
          text
          enabled
      }
      hasGallery
      galleryTitle
      galleryId
      galleryRedirect
      galleryDefaultRedirectUrl
      instagram {
          profileImageUrl
      }
      googleAnalytics
      googleAnalytics4
      pixel
      css
      style {
        buttonRadius
        buttonBorder
        buttonBorderColour
        buttonFontColour
        buttonBackground
        buttonOpacity
        buttonBoxShadowColour
        background
        fontColour
      }
      updatedAt
      prefetch
    }
  }
`;

export const getDraftPreviewOptions = ({
  name: 'draftPreview',
  options: ({ authenticationState: { authenticationState: { authenticated, selectedAccount } } }) => ({ variables: { instagramUsername: selectedAccount } }),
  skip: ({ authenticationState }) => {
    if (authenticationState && authenticationState.authenticationState && authenticationState.authenticationState.selectedAccount) {
      return false;
    }

    return true;
  }
});
